// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
// RxJS
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';
//import { debug } from 'util';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {

	constructor(private router: Router) { }
	// intercept request and add token
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {

		let token = localStorage.getItem('userdata');

		if (token != null) {
            request = request.clone({
                setHeaders: {
                    Authorization: "Basic " + window.btoa("admin:1234adm2"),
                    'accessToken': `${localStorage.getItem('userdata')}`
                }
            });
        }else {
            request = request.clone({
                setHeaders: {
                    //Authorization: `Bearer ${currentUser.token}`,
                    Authorization: "Basic " + window.btoa("admin:1234adm2")
                }
            });

        }


		return next.handle(request).pipe(
			tap(
				event => {
					 if (event instanceof HttpResponse) {

						if(event.body.status == 401){
							
							localStorage.removeItem('userdata');
							
							this.router.navigate(['/auth/login'])
						}
					}
				},
				error => {
					
					
					//console.error(error.status);
					//console.error(error.message);
					this.router.navigate(['/auth/login'])
				}
			)
		);
	}
}
