import { BaseModel } from '../../_base/crud';

export class ProductModel extends BaseModel {

	active: boolean
	address1: string
	address2: string
	category: string
	city: string
	createdAt: Date
	email: string
	fullAddress: string
	id: string
	image: string
	isNotification: boolean
	longLat: {}
	mobile: string
	operationHours: []
	cat:[]
	password: string
	postalCode: string
	province: string
	storeName: string
	type: string
	isBlock:boolean
	websiteUrl: string
	clear() {

		this.active = false
		this.address1 = ""
		this.address2 = ""
		this.category = ""
		this.city = ""
		this.createdAt = new Date()
		this.email = ''
		this.fullAddress = ''
		this.id = ''
		this.image = ''
		this.isNotification = false
		//longLat: {type: "Point", coordinates: [-0.1337, 51.50998]}
		this.mobile = ''
		this.operationHours = []
		this.cat = []
		this.password = ''
		this.postalCode = ''
		this.province = ''
		this.storeName = ''
		this.type = ''
		this.websiteUrl = ''
		this.isBlock = false
	}
}





