export class QueryResultsModel {
	// fields
	output:any
	list: any[];
	resultCount: number;
	errorMessage: string;

	constructor(_list: any[] = [], _resultCount: number = 0, _errorMessage: string = '') {
		this.list = _list;
		this.resultCount = _resultCount;
	}
}
