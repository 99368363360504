export const environment = {
  production: false,
  isMockEnabled: true, // You have to switch this, when your real back-end is done
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  API_URL: 'http://ec2-99-79-185-70.ca-central-1.compute.amazonaws.com:4632/V1/',//Development
  // API_URL:'http://ec2-35-183-71-145.ca-central-1.compute.amazonaws.com:4632/V1/'//Live-production last
  // API_URL:'http://ec2-35-183-214-238.ca-central-1.compute.amazonaws.com:4632/V1/' // latest production
  // API_URL:'http://api.rounduponline.ca//V1/' // latest production

  // API_URL:'http://ec2-15-223-44-192.ca-central-1.compute.amazonaws.com:4632/V1/' 
  // API_URL:'http://35.183.214.238:4632/V1/'

};
