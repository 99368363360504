import { BaseModel } from '../../_base/crud';

export class CustomerModel extends BaseModel {

	contentType: []
	createdAt: string
	description: string
	isDelete: boolean
	keywords: []
	name: ""
	pdfUrlAr: []
	preferences: []
	sectors: []
	status: number
	textUrl: string
	topics: []
	updatedAt: string
	videoUrl: string
	viewCount: number
	wordDocUrl: string
	workplaceSituations: []
	id: string
	active:boolean


	clear() {
		this.id = ""
		this.name = ""
		this.createdAt = ""
		this.description = ""
		this.isDelete = false
		this.keywords = []
		this.status = 0
		this.textUrl = ""
		this.viewCount = 0
		this.pdfUrlAr=[]
		this.workplaceSituations = []
		this.active = false
	}
}





